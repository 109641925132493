.heatmap {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
}

.months {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.month {
  width: calc(110% / 12);
  text-align: center;
  @media (max-width: 1080px) {
   font-size: 7px;

  }
}

.days-and-weeks {
  display: flex;
}

.days {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  @media (max-width: 1080px) {
    color: rgba(0, 0, 0, 0);

  }

}

.day {
  height: 20px;
  text-align: right;
  padding-right: 5px;
}

.weeks {
  display: flex;
  flex-wrap: wrap;
}

.week {
  display: flex;
  flex-direction: column;
  margin-right: 2px;
}

.week .day {
  width: 10px;
  height: 10px;
  margin-bottom: 3px;
  border-radius: 3px; /* Скругление углов */
}
.legend-container {
  display: flex;
  justify-content: right;
  width: 100%;
}

.legend {
  display: flex;
  align-items: center;
}

.legend span {
  margin: 0 10px;
}

.legend-colors {
  display: flex;
}

.legend-colors div {
  width: 10px;
  height: 10px;
  margin-right: 2px;
  border-radius: 3px;
}

@media (max-width: 1080px) {

  .week .day {
    width: 7px;
    height: 7px;
    margin-bottom: 3px;
    border-radius: 3px; /* Скругление углов */
  }
}
@media (max-width: 540px) {

  .week .day {
    width: 3px;
    height: 3px;
    margin-bottom: 2px;
    border-radius: 3px; /* Скругление углов */
  }
}
