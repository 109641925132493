.activity-grid-container {
  display: flex;
  justify-content: center;
}

.activity-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(6, 1fr);
  gap: 2px;
}

.grid-item {
  width: 20px;
  height: 20px;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #fff;
}

.grid-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #959595;
}

.day-label {
  grid-column: span 1;
}

.time-label {
  grid-row: span 1;
}

.grid-empty {
  width: 20px;
  height: 20px;
}
