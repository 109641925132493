@font-face {
  font-family: 'Minecraft Rus';
  src: url("../Font/minecraft.ttf") format('truetype');
}
@font-face {
  font-family: 'ArchivoBlack';
  src: url("../Font/ArchivoBlack-Regular.ttf") format('truetype');
}

.custom-navbar {
  width: 70%;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.33);
  border-radius: 40px;
  padding-left: 30px;
  padding-right: 30px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: top 0.3s; /* Плавный переход для появления/исчезания */
  z-index: 1000;
  font-family: 'Minecraft Rus', sans-serif;
}

.custom-navbar.is-hidden {
  top: -80px; /* значение в зависимости от высоты панели */

}

.navbar-brand img {
  width: 50px;
  height: 50px;
}

.nav-link {
  color: #ffffff !important;
  margin-right: 20px;
}

.navbar-toggler {
  border: none;
}

.btn-outline-success {
  background: linear-gradient(90deg, #01B3F7 0%, #00E2B2 100%);
  color: white;
  border-radius: 65px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'ArchivoBlack', sans-serif;

}

.btn-outline-success:hover {
  background-color: #28a745;
  color: #fff;
}




.nav-link {
  color: #ffffff !important;
  margin-right: 20px;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #00ffc4 !important;
  text-decoration: none;
}
