@font-face {
  font-family: 'ArchivoBlack';
  src: url("../Font/ArchivoBlack-Regular.ttf") format('truetype');
}
.profile {
  height: 100vh;
  font-family: Arial, Helvetica, sans-serif;

  .card {
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    border-radius: 15px;
  }

  .img-fluid {
    border-radius: 15px;
  }


  .grid-container {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 5px;
  }

  .grid-item {
    width: 20px;
    height: 20px;
    background-color: rgba(204, 204, 204, 0.3);
  }
  .custom-gray-text {
    color: #888;
  }


  .badges {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    .badge-item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 8px;
      position: relative;
      background-color: #007bff;
      color: white;
      border-radius: 10px;
      cursor: pointer;

      span {
        display: none;
        margin-left: 5px;
        cursor: pointer;
      }

      &:hover span {
        display: inline;
      }
    }
  }

  button {
    background-color: rgba(255, 255, 255, 0);
    color: rgba(253, 253, 253, 0.66);
    border-color: rgba(26, 26, 28, 0);
  }
}
.castom_profile{
  width: 720px;
}

@media (max-width: 1080px) {
  .castom_profile{
    width: 520px;
  }
}

@media (max-width: 770px) {
  .profile{
    height: 1700px;
  }
  .castom_profile{
    width: 520px;
  }
}
@media (max-width: 540px) {
  .profile{
    height: 1700px;
  }
  .castom_profile{
    width: 100%;
  }
}