.GuildContent {
  height: 1080px;
  @media (max-width: 580px) {
    height: 100%;
    .col{
      padding: 0 20px;
    }

  }
}
.back_img {
  background-image: url('../img/Frame.jpg');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.GuildContent {
  position: relative;
  z-index: 1;
  padding: 40px;
  .guild-card-row {
    margin-left: -30px; /* Учет отрицательного маргинала Bootstrap для компенсации */
    margin-right: -30px; /* Учет отрицательного маргинала Bootstrap для компенсации */

    .col {
      padding: 0 30px; /* Увеличение отступа между карточками */
    }
  }
}
