.map {
  height: 980px;

  @media (max-width: 1600px) {
    height: 850px;
  }
  @media (max-width: 800px) {
    margin: 10px 0 20px 0;
    height: 700px;
  }
  @media (max-width: 600px) {
    height: 650px;
  }
  @media (max-width: 500px) {
    height: 560px;
  }
}


.MapPage{
  background-image: url('../img/Frame.jpg');
  background-size: cover;
  background-position: center;
}
