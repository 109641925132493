footer {
  background: linear-gradient(to right, rgb(28, 172, 117), rgb(34, 99, 170));
  color: white;
  padding: 20px 0;
  position: relative;
  bottom: 0;
  width: 100%;
  font-family: ArchivoBlack, sans-serif !important;
  margin-top: auto;
}

.footer-info p {
  margin: 5px;

}

.footer-info a {
  color: white;
  text-decoration: none;
  margin: 0 15px;
}

.footer-info a:hover {
  text-decoration: underline;
}

.p_2 {
  font-size: 20px;
}
