@font-face {
  font-family: 'Minecraft Rus';
  src: url("../Font/minecraft.ttf") format('truetype');
}

@font-face {
  font-family: 'ArchivoBlack';
  src: url("../Font/ArchivoBlack-Regular.ttf") format('truetype');
}
.body{

}

.main-content {
  text-align: center;
  width: 100%;
  padding: 2rem;
  justify-content: left;
  background-image: url('../img/Body.jpg');
  background-size: cover;
  background-position: center;

  h1{
    padding-top: 100px;
  }


  .content-block {
    height: 90vh;
    justify-content: left;
    align-items: center;
    text-align: left;



    @media (min-width: 2000px) {
      margin: 0 auto;
      width: 1920px;
    }

    .gradient-text {
      font-size: 140px;
      padding-top: 200px;
      font-family: ArchivoBlack, sans-serif;
      background: linear-gradient(50deg, #10c0ff, #08ff00); /* Увеличим угол наклона градиента */
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: gradientAnimation 8s ease infinite; /* Добавим анимацию */
    }






    p {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
      font-family: "Minecraft Rus", sans-serif;
      padding-bottom: -5px;
    }

    .start-button {

      background: linear-gradient(90deg, #01B3F7 0%, #00E2B2 100%);
      color: white;
      border-radius: 65px;
      border: none;
      padding: 15px 30px;
      cursor: pointer;
      font-size: 25px;

      font-weight: bold;
      font-family: "Minecraft Rus", sans-serif;

      &:hover {
        background-color: #00cc99;
      }
    }


    @media (max-width: 1200px) {

      height: 700px;
      .gradient-text {
        font-size: 100px;
        padding-top: 150px;
      }
      p {
        font-size: 1.2rem;
      }
      .start-button {
        font-size: 20px;
        padding: 12px 25px;
      }
    }

    @media (max-width: 768px) {

      height: 500px;
      .gradient-text {
        font-size: 80px;
        padding-top: 100px;
      }
      p {
        font-size: 1rem;
      }
      .start-button {
        font-size: 18px;
        padding: 10px 20px;
      }
    }

    @media (max-width: 480px) {

      height: 400px;
      .gradient-text {
        font-size: 60px;
        padding-top: 50px;
      }
      p {
        font-size: 0.8rem;
      }
      .start-button {
        font-size: 16px;
        padding: 8px 15px;
      }
    }


  }

  .features {
    width: 100%;
    margin-top: 3rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
    min-height: 90vh;


    margin-bottom: 3rem;

    @media (min-width: 2000px) {
      margin: 0 auto;
      width: 1920px;
    }

    h2 {
      margin-bottom: 2rem;
      margin-top: 6rem;
      font-size: 40px;
      font-weight: 700;
      font-family: "Minecraft Rus", sans-serif;
    }

    .feature-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      font-family: ArchivoBlack, sans-serif;
    }

    @media (max-width: 1200px) {
      min-height: 1200px;
      h2 {
        font-size: 30px;
      }
    }

    @media (max-width: 768px) {
      min-height: 1300px;
      h2 {
        font-size: 20px;
      }
    }

    @media (max-width: 480px) {
      min-height: 1600px;
      h2 {
        font-size: 20px;
      }
    }
  }


  .announcement {
    margin-top: 3rem;
    text-align: left;
    height: 100vh;

    img {
      width: 100%;
      height: auto;
      margin-top: 1rem;
    }
  }
}


.social-icons {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.social-icons a {
  margin: 0 10px;
  color: white;
  font-size: 24px;
}

.social-icons a:hover {
  color: #00aaff; /* Optional hover color */
}

.news-section {
  height: 800px;
  margin-top: 5rem;



}
















