.fireflies-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.firefly {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: rgba(255, 246, 146, 0.5);
  border-radius: 50%;
  pointer-events: auto;
  animation: flicker 1s infinite alternate;
}

@keyframes flicker {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
