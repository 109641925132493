// src/components/RulesPage.scss
@font-face {
  font-family: 'ArchivoBlack';
  src: url('../Font/ArchivoBlack-Regular.ttf') format('truetype');
}
.back_img{
  background-image: url('../img/Frame.jpg');
  background-size: cover;
  background-position: center;
}


.rules-page {

  padding: 20px;
  text-align: center;
  color: white;
  background: rgba(0, 0, 0, 0.56); // Полупрозрачный черный фон
  border-radius: 10px;

  font-family: ArchivoBlack, sans-serif;
  @media (min-width: 2000px) {
    margin: 0 auto;
    width: 1920px;
  }
  .image-container {
    text-align: center;
    margin: 20px 0;
  }
  .image-container img {
    border-radius: 15px;
    border: 2px solid white;
    max-width: 100%;
    height: auto;
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    margin-top: 20px;
    background: linear-gradient(7deg, #10c0ff, #08ff00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
  }


  h2 {
    font-size: 1rem;
    margin-bottom: 2rem;
    color: #cccccc;
    padding-top: 60px;
  }

  h3 {
    background-color: #019f7e;
    color: white;
    font-size: 1rem;
    width: 111px;
  }

  h4 {
    background-color: #01409f;
    color: white;
    font-size: 1rem;
    width: 100px;
    margin-top: 5px;

  }

  .p_1 {
    font-size: 1.2rem;
    color: #00f5ff;
  }

  .rules-content {
    text-align: left;

    .rule {
      font-size: 1rem;
      margin-bottom: 1rem;
      padding: 10px;
      background: rgba(255, 255, 255, 0.1); // Полупрозрачный белый фон для блока правила
      border-radius: 5px;
      word-spacing: 3px;
    }
  }

  @media (max-width: 1200px) {
    margin: 20px 20px 20px 20px;
    h1 {
      font-size: 1.8rem;
    }

  }
  @media (max-width: 768px) {
    margin: 20px 20px 20px 20px;
    h1 {
      font-size: 1.6rem;
    }
  }
  @media (max-width: 480px) {
    margin: 10px 10px 10px 10px;
    h1 {
      font-size: 1.2rem;
    }
  }
}
