.NotFoundPage_Content {
  height: 1020px;

  h1 {
    text-align: center;
    font-size: 20px;

  }
}
.back_img{
  background-image: url('../img/Frame.jpg');
  background-size: cover;
  background-position: center;
}